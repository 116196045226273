import React from 'react'

import logo from '../../images/logo-full-large.png'

export default function ({}) {
    return (
        <div>
            <img style={{ maxWidth:"100%" }} src={logo} alt="SRUSA logo" />
        </div>
    )
}
