import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import SRUSALogo from '../components/SRUSALogo'
import SRUSACompanyInfo from '../components/SRUSACompanyInfo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <SRUSALogo />
    <SRUSACompanyInfo />
  </Layout>
)

export default IndexPage
