import React from 'react'

export default function(props) {
    return(
        <div style={{ textAlign:"center" }}>
            <div><span style={{ color: "blue" }}>STRATEGIX RESPONSE UNIT SA PTY (LTD)</span> (CK No: 2017/538695/07)</div> 
            <div>15 GROVE END DRIVE , STANMORE, 4068</div>
            <div>E-mail: <a href="mailto:info@srusa.co.za">info@srusa.co.za</a></div>
            <div>Office: <a href="tel:07315023340">+27 31 502 3340</a></div>
            <div>Mobile: <a href="tel:07677247365">+27 677 247 365</a> / <a href="tel:0836639438">083 663 9438</a></div>
        </div>
    )
}